@keyframes colorChange {
	0%   { background: linear-gradient(180deg, #ffffff 100%, #047394 0%); }
	1%   { background: linear-gradient(180deg, #ffffff 99%,  #047394 1%); }
	2%   { background: linear-gradient(180deg, #ffffff 98%,  #047394 2%); }
	3%   { background: linear-gradient(180deg, #ffffff 97%,  #047394 3%); }
	4%   { background: linear-gradient(180deg, #ffffff 96%,  #047394 4%); }
	5%   { background: linear-gradient(180deg, #ffffff 95%,  #047394 5%); }
	6%   { background: linear-gradient(180deg, #ffffff 94%,  #047394 6%); }
	7%   { background: linear-gradient(180deg, #ffffff 93%,  #047394 7%); }
	8%   { background: linear-gradient(180deg, #ffffff 92%,  #047394 8%); }
	9%   { background: linear-gradient(180deg, #ffffff 91%,  #047394 9%); }
	10%  { background: linear-gradient(180deg, #ffffff 90%,  #047394 10%); }
	11%  { background: linear-gradient(180deg, #ffffff 89%,  #047394 11%); }
	12%  { background: linear-gradient(180deg, #ffffff 88%,  #047394 12%); }
	13%  { background: linear-gradient(180deg, #ffffff 87%,  #047394 13%); }
	14%  { background: linear-gradient(180deg, #ffffff 86%,  #047394 14%); }
	15%  { background: linear-gradient(180deg, #ffffff 85%,  #047394 15%); }
	16%  { background: linear-gradient(180deg, #ffffff 84%,  #047394 16%); }
	17%  { background: linear-gradient(180deg, #ffffff 83%,  #047394 17%); }
	18%  { background: linear-gradient(180deg, #ffffff 82%,  #047394 18%); }
	19%  { background: linear-gradient(180deg, #ffffff 81%,  #047394 19%); }
	20%  { background: linear-gradient(180deg, #ffffff 80%,  #047394 20%); }
	21%  { background: linear-gradient(180deg, #ffffff 79%,  #047394 21%); }
	22%  { background: linear-gradient(180deg, #ffffff 78%,  #047394 22%); }
	23%  { background: linear-gradient(180deg, #ffffff 77%,  #047394 23%); }
	24%  { background: linear-gradient(180deg, #ffffff 76%,  #047394 24%); }
	25%  { background: linear-gradient(180deg, #ffffff 75%,  #047394 25%); }
	26%  { background: linear-gradient(180deg, #ffffff 74%,  #047394 26%); }
	27%  { background: linear-gradient(180deg, #ffffff 73%,  #047394 27%); }
	28%  { background: linear-gradient(180deg, #ffffff 72%,  #047394 28%); }
	29%  { background: linear-gradient(180deg, #ffffff 71%,  #047394 29%); }
	30%  { background: linear-gradient(180deg, #ffffff 70%,  #047394 30%); }
	31%  { background: linear-gradient(180deg, #ffffff 69%,  #047394 31%); }
	32%  { background: linear-gradient(180deg, #ffffff 68%,  #047394 32%); }
	33%  { background: linear-gradient(180deg, #ffffff 67%,  #047394 33%); }
	34%  { background: linear-gradient(180deg, #ffffff 66%,  #047394 34%); }
	35%  { background: linear-gradient(180deg, #ffffff 65%,  #047394 35%); }
	36%  { background: linear-gradient(180deg, #ffffff 64%,  #047394 36%); }
	37%  { background: linear-gradient(180deg, #ffffff 63%,  #047394 37%); }
	38%  { background: linear-gradient(180deg, #ffffff 62%,  #047394 38%); }
	39%  { background: linear-gradient(180deg, #ffffff 61%,  #047394 39%); }
	40%  { background: linear-gradient(180deg, #ffffff 60%,  #047394 40%); }
	41%  { background: linear-gradient(180deg, #ffffff 59%,  #047394 41%); }
	42%  { background: linear-gradient(180deg, #ffffff 58%,  #047394 42%); }
	43%  { background: linear-gradient(180deg, #ffffff 57%,  #047394 43%); }
	44%  { background: linear-gradient(180deg, #ffffff 56%,  #047394 44%); }
	45%  { background: linear-gradient(180deg, #ffffff 55%,  #047394 45%); }
	46%  { background: linear-gradient(180deg, #ffffff 54%,  #047394 46%); }
	47%  { background: linear-gradient(180deg, #ffffff 53%,  #047394 47%); }
	48%  { background: linear-gradient(180deg, #ffffff 52%,  #047394 48%); }
	49%  { background: linear-gradient(180deg, #ffffff 51%,  #047394 49%); }
	50%  { background: linear-gradient(180deg, #ffffff 50%,  #047394 50%); }
	51%  { background: linear-gradient(180deg, #ffffff 49%,  #047394 51%); }
	52%  { background: linear-gradient(180deg, #ffffff 48%,  #047394 52%); }
	53%  { background: linear-gradient(180deg, #ffffff 47%,  #047394 53%); }
	54%  { background: linear-gradient(180deg, #ffffff 46%,  #047394 54%); }
	55%  { background: linear-gradient(180deg, #ffffff 45%,  #047394 55%); }
	56%  { background: linear-gradient(180deg, #ffffff 44%,  #047394 56%); }
	57%  { background: linear-gradient(180deg, #ffffff 43%,  #047394 57%); }
	58%  { background: linear-gradient(180deg, #ffffff 42%,  #047394 58%); }
	59%  { background: linear-gradient(180deg, #ffffff 41%,  #047394 59%); }
	60%  { background: linear-gradient(180deg, #ffffff 40%,  #047394 60%); }
	61%  { background: linear-gradient(180deg, #ffffff 39%,  #047394 61%); }
	62%  { background: linear-gradient(180deg, #ffffff 38%,  #047394 62%); }
	63%  { background: linear-gradient(180deg, #ffffff 37%,  #047394 63%); }
	64%  { background: linear-gradient(180deg, #ffffff 36%,  #047394 64%); }
	65%  { background: linear-gradient(180deg, #ffffff 35%,  #047394 65%); }
	66%  { background: linear-gradient(180deg, #ffffff 34%,  #047394 66%); }
	67%  { background: linear-gradient(180deg, #ffffff 33%,  #047394 67%); }
	68%  { background: linear-gradient(180deg, #ffffff 32%,  #047394 68%); }
	69%  { background: linear-gradient(180deg, #ffffff 31%,  #047394 69%); }
	70%  { background: linear-gradient(180deg, #ffffff 30%,  #047394 70%); }
	71%  { background: linear-gradient(180deg, #ffffff 29%,  #047394 71%); }
	72%  { background: linear-gradient(180deg, #ffffff 28%,  #047394 72%); }
	73%  { background: linear-gradient(180deg, #ffffff 27%,  #047394 73%); }
	74%  { background: linear-gradient(180deg, #ffffff 26%,  #047394 74%); }
	75%  { background: linear-gradient(180deg, #ffffff 25%,  #047394 75%); }
	76%  { background: linear-gradient(180deg, #ffffff 24%,  #047394 76%); }
	77%  { background: linear-gradient(180deg, #ffffff 23%,  #047394 77%); }
	78%  { background: linear-gradient(180deg, #ffffff 22%,  #047394 78%); }
	79%  { background: linear-gradient(180deg, #ffffff 21%,  #047394 79%); }
	80%  { background: linear-gradient(180deg, #ffffff 20%,  #047394 80%); }
	81%  { background: linear-gradient(180deg, #ffffff 19%,  #047394 81%); }
	82%  { background: linear-gradient(180deg, #ffffff 18%,  #047394 82%); }
	83%  { background: linear-gradient(180deg, #ffffff 17%,  #047394 83%); }
	84%  { background: linear-gradient(180deg, #ffffff 16%,  #047394 84%); }
	85%  { background: linear-gradient(180deg, #ffffff 15%,  #047394 85%); }
	86%  { background: linear-gradient(180deg, #ffffff 14%,  #047394 86%); }
	87%  { background: linear-gradient(180deg, #ffffff 13%,  #047394 87%); }
	88%  { background: linear-gradient(180deg, #ffffff 12%,  #047394 88%); }
	89%  { background: linear-gradient(180deg, #ffffff 11%,  #047394 89%); }
	90%  { background: linear-gradient(180deg, #ffffff 10%,  #047394 90%); }
	91%  { background: linear-gradient(180deg, #ffffff 9%,   #047394 91%); }
	92%  { background: linear-gradient(180deg, #ffffff 8%,   #047394 92%); }
	93%  { background: linear-gradient(180deg, #ffffff 7%,   #047394 93%); }
	94%  { background: linear-gradient(180deg, #ffffff 6%,   #047394 94%); }
	95%  { background: linear-gradient(180deg, #ffffff 5%,   #047394 95%); }
	96%  { background: linear-gradient(180deg, #ffffff 4%,   #047394 96%); }
	97%  { background: linear-gradient(180deg, #ffffff 3%,   #047394 97%); }
	98%  { background: linear-gradient(180deg, #ffffff 2%,   #047394 98%); }
	99%  { background: linear-gradient(180deg, #ffffff 1%,   #047394 99%); }
	100% { background: linear-gradient(180deg, #ffffff 0%,   #047394 100%); }
  }
  

  
  

/* @keyframes cubeFloat {
	0% {
		background-image: url(../../../Images/Hero/Boxes.png);
	}
	25% {
		background-image: url(../../../Images/Hero/BoxesAnimation2.png);
	}
	40% {
		background-image: url(../../../Images/Hero/BoxesAnimation3.png);
	}
	75% {
		background-image: url(../../../Images/Hero/BoxesAnimation4.png);
	}
	100% {
		background-image: url(../../../Images/Hero/Boxes.png);
	}
}

.cubeAnimation {
	animation-name: cubeFloat;
	animation-iteration-count: infinite;
	animation-duration: 5s;
	animation-timing-function: ease;
	width: 100%;
	height: 470px;
	background-repeat: no-repeat;
	background-position: center;
} */

.colorAnimation {
	animation-name: colorChange;
	animation: colorChange 2s infinite alternate;
}

@media (max-width: 875px) {
	.tabletCube {
		height: 300px;
		z-index: -1;
	}
}
