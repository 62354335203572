/* RESPONSIVENESS */
.service-hero-image-fiber {
    position: absolute;
    bottom: -215px;
}
.fiber-class-show {
    display: block !important;
}
@media (max-width: 1199px) {
	.service-image {
        height: 320px;
    }
    .service-image2 {
        height: 410px;
    }
    .service-image3 {
        height: 220px;
    }
}

@media (max-width: 959px) {
	.service-image {
        height: 290px;
    }
    .service-image2 {
        height: 380px;
    }
    .service-image3 {
        height: 190px;
    }
    .service-hero-image-fiber {
        bottom: -50px
    }
}

@media (max-width: 599px) {
	.service-image {
        height: 210px;
    }
    .service-image2 {
        height: 290px;
    }
    .service-image3 {
        height: 170px;
    }
}