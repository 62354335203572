:root {
	--navbar-color: rgba(7, 81, 108, 1);
	--text-color: #2d2d2d;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}
html, body {
	overflow-x: hidden;
  }

.app {
	min-height: 75vh;
	margin-top: 0px;
}

.app-wrapper {
	flex: 1 1;
	min-height: 100vh;
	position: relative;
}

/* Text Field */
.textField div {
	border-radius: 2px !important;
	font-family: "Roboto" !important;
}

.textField input {
	padding: 12px 8px !important;
	color: #94a3b8 !important;
}

.textField fieldset {
	border-color: #94a3b8 !important;
}

/* Text Area */
.textFieldArea div {
	padding: 24px 10px !important;
	font-family: "Roboto" !important;
}

.textFieldArea textarea {
	color: #94a3b8 !important;
}

.textFieldArea fieldset {
	border-color: #94a3b8 !important;
}

/* scrollbar */
::-webkit-scrollbar {
	height: 8px;
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(177, 177, 177, 0.8);
	border-radius: 10px;
}

/* OVERWRITE MUI */
.MuiButton-root {
	border-radius: 2px !important;
	border-color: #047394 !important;
	color: #ffffff !important;
	background-color: #047394;
	border-radius: 15px !important;
	width: 240px !important;
	font-size: 16px;


}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #047394 !important
}

.MuiInputBase-input:focus {
	color: #047394 !important
}

.MuiButton-root:hover{
	background-color: (4, 115, 148, 0.76);
}