/* Dropdown Menu */
#dropdown-menu {
	z-index: 5 !important;
}

#dropdown-menu .MuiBackdrop-invisible {
	background-color: rgba(0, 0, 0, 0.5) !important;
}

#dropdown-menu ul {
	max-width: 1232px;
	margin: 35px auto auto;
	display: flex;
	justify-content: center;
}

#dropdown-menu li {
	font-size: 18px !important;
	font-weight: 600 !important;
	color: var(--text-color) !important;
	margin-bottom: 30px;
	padding-left: 0;
}

#dropdown-menu .stripe {
	height: 1px;
	background: var(--navbar-color);
	width: 95%;
	margin-bottom: 35px;
}

#dropdown-menu .sub-links {
	font-size: 16px !important;
	font-weight: 400 !important;
	color: var(--text-color) !important;
	margin-bottom: 25px;
}

#dropdown-menu .image {
	width: 24px;
	height: 24px;
	margin-right: 5px;
}

/* ACCORDION */
/* .drawer .MuiDrawer-paper {
	height: 50vh !important;
} */

#colorChange div svg,
#colorChange p {
	color: var(--navbar-color) !important;
}

.accordion-hamburger {
	box-shadow: unset !important;
	margin-bottom: 30px !important;
}

/* .accordion-hamburger:hover {
	background-color: rgba(0, 0, 0, 0.04);
} */

.accordion-hamburger p {
	color: var(--text-color);
	font-size: 18px;
	font-family: "Roboto";
	font-weight: 600;
}

.accordion-hamburger li {
	color: var(--text-color);
	font-size: 18px;
	font-family: "Roboto";
	font-weight: 600;
	padding: 15px 16px !important;
}

.accordion-summary {
	justify-content: left !important;
	min-height: 48px !important;
}

.accordion-summary div {
	margin: 0 !important;
	flex-grow: 0;
}

.accordion-summary div svg {
	color: var(--text-color);
}

.accordion-details {
	padding: 0 !important;
}

.accordion-details .stripe {
	height: 1px;
	background: var(--navbar-color);
	width: 95%;
	margin: 35px 0 15px 16px;
}

.accordion-details .sub-links {
	font-size: 16px !important;
	font-weight: 400 !important;
	color: var(--text-color) !important;
	margin: 20px 0;
	padding: 0 15px !important;
	min-height: 48px !important;
}

.accordion-details .image {
	width: 24px;
	height: 24px;
	margin-right: 5px;
}

/* RESPONSIVENESS */
@media (max-width: 959px) {
	.dropdownButton span {
		justify-content: center !important;
	}
}

@media (max-width: 599px) {
	/* .drawer .MuiDrawer-paper {
		height: 100% !important;
	} */
	.accordion-details .stripe {
		margin: 10px 0 20px 16px;
	}
	.accordion-summary {
		justify-content: space-between !important;
	}
}